import { useState, useEffect } from 'react';

function timeCompare(a, b) {
    const aTime = new Date(a.time);
    const bTime = new Date(b.time)
    if (aTime > bTime)
        return 1;
    if (aTime < bTime)
        return -1;
    return 0;
}

function Donations() {
    const [donations, setDonations] = useState([]);
    const [incentives, setIncentives] = useState([]);

    async function updateIncentives() {
        const res = await fetch('/api/incentives');
        const incentivesRaw = await res.json();
        incentivesRaw.filter(x => x.type === 'bidwar').forEach(bidwar => {
            const options = incentivesRaw.filter(x => x.id.startsWith(bidwar.id + ':'));
            bidwar.options = options;
            options.forEach(option => {
                option.bidwarOption = true;
                if (!('raised' in option))
                    option.raised = 0;
                if (option.type === 'puzzle')
                    option.title = `"${option.name}" by ${option.author}`;
            });
            options.sort((a, b) => b.raised - a.raised);
        });
        setIncentives(incentivesRaw.filter(x => !(x.bidwarOption)));
    }

    async function listenForDonations()
    {
        let lastTimestamp = donations.length
            ? Math.max.apply(null, donations.map(donation => new Date(donation.time)))
            : 0;
        while (true)
        {
            // wait at least 5 seconds between requests just because I'm paranoid
            await new Promise(r => setTimeout(r, 5000));
            const res = await fetch(
                '/api/donations/listen',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ after: lastTimestamp }),
                }
            );
            switch (res.status) {
                case 200:
                    const newDonos = await res.json();
                    setDonations(donos => [...donos, ...newDonos]);
                        // retriggers the useEffect to listen again
                    return;
                case 408:
                    // timeout; send again
                    continue;
                default:
                    const err = await res.json();
                    console.log('Something went wrong', err);
                    return;
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { listenForDonations(); updateIncentives(); }, [donations]);

    donations.sort(timeCompare);
    donations.reverse();

    return <center>
        <div style={{ display: 'flex' }}>
            <div>
                {donations.map(donation => <div style={{ border: '1px solid black', padding: '5px' }} key={JSON.stringify(donation)}>
                    Name: {donation.name}<br/>
                    Amount: {donation.amount}<br/>
                    Message: {donation.message}<br/>
                    Extra info: {donation.extraInfo}<br/>
                    Incentive: {donation.incentive}<br/>
                    Time: {new Date(donation.time).toLocaleString()}
                </div>)}
            </div>
            <div>
                {incentives.filter(x => (x.type === 'bidwar')).map(bidwar => <div style={{ padding: '5px' }} key={bidwar.title}>
                    Bidwar: {bidwar.title}
                    {bidwar.options.map(option => <div style={{ border: '1px solid black', padding: '5px' }} key={option.id}>
                        {option.title}<br/>
                        Raised: {option.raised}
                    </div>)}
                </div>)}
            </div>
            <div>
                {incentives.filter(x => x.goal).map(incentive => <div style={{ border: '1px solid black', padding: '5px' }} key={incentive.title}>
                    Incentive: {incentive.title}<br/>
                    Raised: {incentive.raised ? incentive.raised : 0} / {incentive.goal}
                </div>)}
            </div>
        </div>
    </center>
}

export default Donations;
