import { useState, useEffect } from 'react';

function timeCompare(a, b) {
    const aTime = new Date(a.time);
    const bTime = new Date(b.time)
    if (aTime > bTime)
        return 1;
    if (aTime < bTime)
        return -1;
    return 0;
}

function DonationBar() {
    const [donations, setDonations] = useState([]);

    async function listenForDonations() {
        console.log('listen');
        let lastTimestamp = donations.length
            ? Math.max.apply(null, donations.map(donation => new Date(donation.time)))
            : 0;
        while (true) {
            console.log('loop');
            const res = await fetch(
                '/api/donations/listen',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ after: lastTimestamp }),
                }
            );
            switch (res.status) {
                case 200:
                    const newDonos = await res.json();
                    console.log('200', newDonos);
                    setDonations(donos => [...donos, ...newDonos]);
                    // retriggers the useEffect to listen again
                    return;
                case 408:
                    console.log('408');
                    // timeout; send again
                    continue;
                case 503:
                    console.log('503');
                    // service unavailable; send again, but wait a minute
                    await new Promise(r => setTimeout(r, 15000));
                    continue;
                default:
                    console.log('default');
                    const err = await res.json();
                    console.log('Something went wrong', err);
                    return;
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { listenForDonations(); }, [donations]);

    donations.sort(timeCompare);
    let lastDonationStr = 'No donations yet';
    if (donations.length) {
        const lastDonation = donations[donations.length - 1];
        lastDonationStr = `Last donation: $${lastDonation.amount.toFixed(2)} by ${lastDonation.name ? lastDonation.name : 'Anonymous'}`
    }

    const raised = donations.reduce((sum, donation) => sum + donation.amount, 0);
    const progress = (raised / 50) + '%';

    let rightPart = <div className='goal' style={{ fontSize: '1.5em' }}> Donation goal: $3000</div>;
    if (raised < 500)
        rightPart = <div className='goal' style={{ fontSize: '1.5em', textAlign: 'center' }}>
            <div>Donation goal: $3000</div>
            <div>The first $50 of every donation will be matched!</div>
        </div>
    if (raised >= 3000)
        rightPart = <div className='goal' style={{ fontSize: '1.5em', textAlign: 'center' }}>
            <div>Our donation goal has been met!</div>
            <div>Stretch goal: $5000</div>
        </div>

    return <div style={{ width: '1920px', height: '80px', position: 'relative', fontFamily: 'Montserrat, Calibri, sans-serif' }}>
        <div className='background' style={{ position: 'absolute', width: '100%', height: '100%', background: '#EBC4FF', zIndex: -2 }} />
        <div className='progress' style={{ position: 'absolute', width: progress, height: '100%', background: '#FE7BFD', zIndex: -1 }} />
        <div className='container' style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '0 20px' }}>
            <div className='lastDonation' style={{ fontSize: '1.8em' }}>{lastDonationStr}</div>
            <div className='totalRaised' style={{ fontSize: '2.2em', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto' }}>Total raised: ${raised.toFixed(2)}</div>
            {rightPart}
        </div>
    </div>
}

export default DonationBar;
