import Base from '../components/Base.js';

import ad_dimono from '../images/sponsors/TidingsOfMinos.png';
import ad_james from '../images/sponsors/ArtisanalSudoku.png';

function Home()
{
    return <Base>
        <p>Thank you to all of the viewers and donors of the SudokuCon Livestream Fundraiser!</p>
        <p>Interested in what we're doing? <a href="https://sudokucon.beehiiv.com/" target="_blank" rel="noreferrer">Check out our newsletter!</a></p>
        <p>SudokuCon is supported by:</p>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5%', padding: '2em 0em' }}>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="https://dimono.ca" target="_blank" rel="noreferrer">
                    <img src={ad_dimono} alt="The Tidings of Minos" width='100%' />
                </a>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '10em' }}>
                <a href="http://artisanalsudoku.com" target="_blank" rel="noreferrer">
                    <img src={ad_james} alt="Artisanal Sudoku" width='100%' />
                </a>
            </div>
        </div>
    </Base>
}

export default Home;
