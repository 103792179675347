import Base from '../components/Base.js';

function Schedule() {
    const schedule = [
        {
            start: new Date('2023-11-04T18:00:00.000Z'),
            end: new Date('2023-11-04T18:15:00.000Z'),
            emcees: 'SudokuCon planning team',
            events: 'What is SudokuCon?',
        },
        {
            start: new Date('2023-11-04T18:15:00.000Z'),
            end: new Date('2023-11-04T22:15:00.000Z'),
            emcees: 'FullDeck, Missingafewcards, and Memeristor',
            events: 'Interviews, sudoku in education, and guest appearances from Bremster and FD/MAFC\'s students',
        },
        {
            start: new Date('2023-11-04T22:30:00.000Z'),
            end: new Date('2023-11-05T02:30:00.000Z'),
            emcees: 'Philip Newman, Bill Murphy, and clover',
            events: 'Competitive setting games, tridoku, and trivia with special guest James Sinclair',
        },
        {
            start: new Date('2023-11-05T03:00:00.000Z'),
            end: new Date('2023-11-05T07:00:00.000Z'),
            emcees: 'Kodra',
            events: 'Speed solving and interviews with special guests TanTan Dai and Prasanna Seshadri, plus commentated solves',
        },
        {
            start: new Date('2023-11-05T07:15:00.000Z'),
            end: new Date('2023-11-05T11:15:00.000Z'),
            emcees: 'Carabet, jonn, and Wessel Strijkstra',
            events: 'Fireside chat, setter interviews, tridoku, and pencil puzzle solving',
        },
        {
            start: new Date('2023-11-05T11:45:00.000Z'),
            end: new Date('2023-11-05T15:45:00.000Z'),
            emcees: 'Niverio and Qodec',
            events: 'Niverio\'s hour of speed, team solving, and computer solvers 101 with Qodec',
        },
        {
            start: new Date('2023-11-05T16:00:00.000Z'),
            end: new Date('2023-11-05T18:00:00.000Z'),
            emcees: 'Zetamath and Tristan',
            events: 'Zeta and Tristan\'s Sunday morning stream - but you get to choose the puzzles',
        },
    ]

    function formatDate(date) {
        const month = 'Nov'; // for our purposes
        const day = date.getDate();
        const time = date.toLocaleTimeString().replace(':00 ', ' ');
        return `${month} ${day} ${time}`;
    }

    return <Base>
        <center><h2>Livestream schedule</h2></center>
        The current event has ended. Catch us next time!
    </Base>

    return <div>
        <table style={{ width: '1500px', minWidth: '80vw', maxWidth: '100vw', marginLeft: 'auto', marginRight: 'auto', marginTop: '5em' }}>
            <tr>
                <th>Start</th>
                <th>End</th>
                <th>Emcees</th>
                <th>Activites</th>
            </tr>
            {schedule.map((block, i) => {
                return <tr style={i % 2 ? { background: '#ACB' } : { background: '#CED' }}>
                    <td style={{ padding: '0.2em 0.5em' }}>{formatDate(block.start)}</td>
                    <td style={{ padding: '0.2em 0.5em' }}>{formatDate(block.end)}</td>
                    <td style={{ padding: '0.2em 0.5em' }}>{block.emcees}</td>
                    <td style={{ padding: '0.2em 0.5em' }}>{block.events}</td>
                </tr>
            })}
        </table>
        <center>
            <p>All dates/times converted to your local timezone.</p>
        </center>
    </div>
}

export default Schedule;
