function Policy() {
    return <div style={{ padding: '20px' }}>
        <p>Customer service information:</p>
        <p>For all inquiries, please contact info@sudokucon.com.</p>
        <p>Refunds and returns:</p>
        <p>All donations are non-refundable. Rewards received in exchange for donations cannot be returned or exchanged.</p>
        <p>Privacy policy:</p>
        <p>This website does not use cookies or collect any personal information from users. We do not share information about individual users with third parties.</p>
        <p>If you donate to SudokuCon via PayPal, we may be able to see your name, address, phone number, email address, and other personal information you have provided to PayPal. We use this information only for the purpose of collecting payments via PayPal and providing donation rewards. Please review PayPal's <a href="https://www.paypal.com/us/legalhub/privacy-full" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.paypal.com/us/webapps/mpp/ua/acceptableuse-full?_ga=1.130764367.1787085997.1696273619" target="_blank" rel="noreferrer">Acceptible Use Policy</a> for more details.</p>
        <p>This privacy policy was last updated on October 31, 2023.</p>
    </div>
}

export default Policy;
